import React, { useState } from 'react';
import Layout from '../layout';
import './install.scss';
import ubuntuIc from '../../static/svg/icons/ubuntu-icon.svg';

const Install = (props) => {
  const {
    location,
  } = props;
  const [option, setOption] = useState(0);
  return (
    <Layout pathname={location.pathname} title="Install" className="install-page">
      <div className="install-page-header">
        <p className="install-page-header-title">
          Install self-managed MLREEF
        </p>
        <p className="install-page-header-subtitle">
          aka Nautilus
        </p>
      </div>
      <div className="install-page-on-premises">
        <p className="install-page-on-premises-title">
          Installing MLReef on-premises
        </p>
        <p className="install-page-on-premises-line">
          you can download, install and maintain your own MLReef instance
        </p>
        <p>
          Want us to host your instance, no package required?
          {' '}
          <a className="install-page-on-premises-line" href="https://mlreef.com/register">
            <span style={{ color: 'var(--info)', fontWeight: 'bold' }}>Try MLReef SaaS today</span>
          </a>
        </p>
      </div>
      <div className="install-page-guide">
        <p className="install-page-guide-title">
          Installing guide <span style={{ color: 'var(--info)', fontWeight: 'bold' }}>for Nautilus</span>
        </p>
        <p className="install-page-guide-text-1">
          This is the recommended method for getting started.
          The Linux packages are mature, scalable, and are used today on MLReef.com.
        </p>
        <p className="install-page-guide-text-2">
          Linux installation is quick to install,
          easy to upgrade and contains features to enhance realiability.
          Install via a single package (docker image)
          that bundles all the different services and tools required to run MLReef.
          At least 4GB of RAM is recommended
        </p>

        <div className="install-page-guide-cards">
          <button onClick={() => setOption(0)} type="button" className="instruction-card">
            <img src={ubuntuIc} alt="ubuntu icon" width="50" />
            <p className="title">Ubuntu</p>
            <a href="https://doc.mlreef.com/80-on-prem/0-README.md">
              See instructions
            </a>
          </button>
          <button onClick={() => setOption(1)} type="button" className="instruction-card">
            <img src={ubuntuIc} alt="ubuntu icon" width="50" />
            <p className="title">Ubuntu offline version</p>
            <a className="card-link" href="https://doc.mlreef.com/80-on-prem/1-MLReef_offline.md">
              See instructions
            </a>
          </button>
        </div>

        {option === 0 ? (
          <div className="install-page-guide-instructions">
            <p className="title">Installing MLReef On Premises</p>
            <p>
              The best way to run MLReef on your own on-premises
              infrastructure is the MLReef Nautilus package.
              Nautilus is a single docker image containing everything
              necessary to create machine learning projects and run ML workloads.
            </p>
            <p>
              Nautilus Contains:
            </p>
            <ul>
              <li>MLReef Management Serive</li>
              <li>Postgres</li>
              <li>Gitlab for hosting Git repositories</li>
              <li>Gitlab Runners for running Machine Learning workloads</li>
              <li>API Gateway</li>
            </ul>
            <p className="subtitle">
              Installation
            </p>
            <p>
              In order to run MLReef Nautilus locally you just have to execute the following
              <span className="code-span">docker run</span> command.
              This will start up a local instance of mlreef with persistent docker vilumes named
              <span className="code-span">mlreef-opt</span>, <span className="code-span">mlreef-etc</span>, and
              <span className="code-span">mlreefdb-opt</span> containing all user data.
            </p>
            <code className="command">
              <pre className="line">docker run -it --rm --detach --name mlreef \</pre>
              <pre className="line">--volume /var/run/docker.sock:/var/run/docker.sock \</pre>
              <pre className="line">--volume mlreef-opt:/var/opt/gitlab   \</pre>
              <pre className="line">--volume mlreef-etc:/etc/gitlab       \</pre>
              <pre className="line">--volume mlreefdb-opt:/var/opt/mlreef \</pre>
              <pre className="line">--publish 2022:22                     \</pre>
              <pre className="line">--publish 80:80                       \</pre>
              <pre className="line">--publish 8081:8081                   \</pre>
              <pre className="line">--publish 5050:5050                   \</pre>
              <pre className="line">--publish 10080:10080                 \</pre>
              <pre className="line">--publish 6000:6000                   \</pre>
              <pre className="line">registry.gitlab.com/mlreef/mlreef:latest</pre>
            </code>
            <p>
              The container comes up with a default runner running
              on same docker network on localhost
            </p>
          </div>
        ) : (
          <div>
            <div className="install-page-guide-instructions">
              <p className="title">Installing MLReef on offline server</p>
              <p>
                The best way to run MLReef on your own on-premises infrastructure
                is the MLReef Nautilus package.
                Nautilus is a single docker image containing everything necessary
                to create machine learning projects
                and run ML workloads.
              </p>
              <p>
                Nautilus contains:
              </p>
              <ul>
                <li>MLReef Management Service</li>
                <li> Postgres</li>
                <li>Gitlab for hosting Git repositories</li>
                <li>Gitlab Runners for running Machine Learning workloads</li>
                <li>API Gateway</li>
              </ul>
              <p className="subtitle">Installation</p>
              <p>
                Two steps need to be done in order to run MLReef Nautilus locally
                on a server which has no internet access.
              </p>
              <p>
                1. In the first step,
                run <span className="code-span">bin/build-export-nautilus-offline</span> to pull and tar
                all required images at one place.
              </p>
              <p>
                Then copy the tar files to the offline server at a location of your choice.
              </p>
              <p>
                2. In the second step, run <span className="code-span">bin/build-run-nautilus-offline</span>
                with tar files location as an argument.
              </p>
              <p>
                This will start up the local isntance of MLReef with
                persistent docker volumes named <span className="code-span">mlreef-opt</span>, <span className="code-span">mlreef-etc</span>, and
                <span className="code-span">mlreefdb-opt</span> containig all user data on offline server
              </p>
              <p style={{ fontWeight: 'bold' }}>
                The installation on an online server
              </p>
              <code className="command">
                <pre className="line">git clone git@gitlab.com:mlreef/mlreef.git</pre>
                <pre className="line">bin/build-export-nautilus-offline</pre>
              </code>
              <p>
                Copy the tar files from mlreef-images-tar to the offline server.
                Copy bin/build-run-nautilus-offline script to offline server.
              </p>
              <p style={{ fontWeight: 'bold' }}>
                On the offline host
              </p>
              <code className="command">
                <pre className="line">
                  bin/build-run-nautilus-offline -d $THE_PATH_OF_TAR_FILES -s $PIP_SERVER(optional)
                </pre>
              </code>
              <p>
                Example:
              </p>
              <code className="command">
                <pre className="line">bin/build-run-nautilus-offline -d mlreef-images-tar</pre>
                <pre className="line">bin/build-run-nautilus-offline -d mlreef-images-tar -s localhost:10010/simple</pre>
                <pre className="line">bin/build-run-nautilus-offline -d mlreef-images-tar -s https://python.example.com/</pre>
              </code>
              <p>
                This will start up a local instance of mlreef with persistent docker volumes named
                <span className="code-span">mlreef-opt</span>, <span className="code-span">mlreef-etc</span>,
                and <span className="code-span">mlreefdb-opt</span> containing all user data.
                The container comes up with a default runner running
                on same docker network on localhost.
                In order to run MLReef Nautilus locally with local volume binding,
                you will have to replace docker volumes in docker run command of
                bin/build-run-nautilus-offline script with persistent data volumes.
              </p>
              <p>
                In order to run MLReef Nautilus locally with local volume binding,
                you will have to replace docker volumes in docker run command of
                <span className="code-span">bin/build-run-nautilus-offline</span> script with persistent data volumes.
              </p>
              <code className="command">
                <pre className="line">
                  --volume /root/mlreef-gitlab-opt:/var/opt/gitlab   \
                </pre>
                <pre className="line">--volume /root/mlreef-gitlab-etc:/etc/gitlab       \</pre>
                <pre className="line">--volume /root/mlreefdb-opt:/var/opt/mlreef        \</pre>
              </code>
              <p>Notes for pip server in offline mode:</p>
              <ul>
                <li style={{ marginBottom: '1rem' }}>If the pip server is running on the same offline host with MLReef,
                  localhost needs to be used for pip server URL, e.g : localhost:10010/simple .
                </li>
                <li>
                  If the pip server is running on some other host in intra network,
                  the DNS host entry needs to be configured for docker.
                </li>
              </ul>
              <p>Example for Ubuntu:</p>
              <p>1. Get the DNS Server IP</p>
              <code className="command">
                <pre className="line">
                  $ nmcli dev show | grep 'IP4.DNS'
                </pre>
                <pre className="line">
                  IP4.DNS[1]:
                </pre>
              </code>
              <p>
                2. Edit 'dns' in /etc/docker/daemon.json (create this file if already not there).
                Multiple DNS server IPs can be added separated by comma.
              </p>
              <code className="command">
                <pre className="line">{'{'}</pre>
                <pre className="line">    "dns": ["192.168.0.1"]</pre>
                <pre className="line">{'}'}</pre>
              </code>
              <p>3. Restart docker</p>
              <code className="command">
                <pre className="line">$ sudo service docker restart</pre>
              </code>
              <p>Now, the PIP server host should be accessible from mlreef service as well.</p>
              <p>Installing a pypi server</p>
              <p>Install pypiserver (<a href="https://pypi.org/project/pypiserver">https://pypi.org/project/pypiserver</a>) with this command:</p>
              <code className="command">
                <pre className="line">pip install pypiserver</pre>
                <pre className="line">mkdir ~/packages                      # put offline python packages into this directory.</pre>
              </code>
              <p>
                Copy some packages into your ~/packages folder
                and then get your pypiserver up and running:
              </p>
              <code className="command">
                <pre className="line">
                  python3 -m pip download -d ~/packages -r {'<requirements file>'}
                </pre>
              </code>
              <p>
                Start the server with this command, you can choose a different port number:
              </p>
              <code className="command">
                <pre className="line">
                  pypi-server -p 10010 ~/packages &      # Will listen to all IPs.
                </pre>
              </code>
              <p>
                From the client computer, type this to test if the pypip server is working:
              </p>
              <code className="command">
                <pre className="line">
                  pip install --extra-index-url http://localhost:10010/simple/ {'<package-name>'}
                </pre>
              </code>
            </div>
          </div>
        )}
      </div>
    </Layout>
  );
};

export default Install;
